var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showheader)?_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('h2',{staticClass:"mb-1 text-primary"},[_vm._v("- "+_vm._s(_vm.title))])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"6"}},[_c('b-button',{attrs:{"id":"add-button","variant":"primary","to":{
          name: 'apps-labtest-create',
          params: { case_id: _vm.case_id, type: _vm.type },
        }}},[_vm._v(" "+_vm._s(_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.actions.create'))+" ")])],1)],1):_vm._e(),_c('b-table',{ref:"refElementsListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.search.not_found')},scopedSlots:_vm._u([(_vm.type == 'FOLLOWUP')?{key:"cell(next_consult)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getNextConsult(data.item))+" ")]}}:null,{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name ? data.item.name : data.item.report.name)+" ")]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.date))+" ")]}},{key:"cell(result)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.result ? data.item.result.value : '')+" ")]}},{key:"cell(provider)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.provider ? data.item.provider.name : '')+" ")]}},{key:"cell(made_by_avenir)",fn:function(data){return [(data.item.made_by_avenir)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('boolean.yes')))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('boolean.no'))+" ")])]}},{key:"cell(report)",fn:function(data){return [(data.item.report)?_c('div',[_c('a',{staticClass:"btn mb-1 mb-sm-0 mr-0 mr-sm-1 btn-secondary",attrs:{"id":"view-report-button","href":_vm.getUrl(data.item.report),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.download'))+" ")]),_c('b-tooltip',{attrs:{"target":"view-report-button","title":_vm.$t(_vm.RESOURCES_ELEMENT_NAME + '.download'),"triggers":"hover","noninteractive":true,"fallback-placement":"counterclockwise"}})],1):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-col',{staticClass:"flex flex-row flex-wrap"},[_c('b-button',{staticStyle:{"margin":"1px"},attrs:{"id":("view-button-" + (data.item.id)),"variant":"primary","to":{
            name: 'apps-labtest-view',
            params: { case_id: _vm.case_id, id: data.item.id },
          }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1),_c('b-button',{staticStyle:{"margin":"1px"},attrs:{"id":("edit-button-" + (data.item.id)),"variant":"primary","to":{
            name: 'apps-labtest-edit',
            params: { case_id: _vm.case_id, id: data.item.id },
          }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-tooltip',{attrs:{"target":("view-button-" + (data.item.id)),"title":_vm.$t('actions.details'),"triggers":"hover","noninteractive":true,"fallback-placement":"counterclockwise"}}),_c('b-tooltip',{attrs:{"target":("edit-button-" + (data.item.id)),"title":_vm.$t('actions.edit'),"triggers":"hover","noninteractive":true,"fallback-placement":"counterclockwise"}})],1)]}}],null,true)}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.total,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }